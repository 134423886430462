$product-carousel-button-width: 26px;

.product {
  @include font-size(1em);

  .favorite {
    transition: color linear $animate-css-animation-duration / 2;
    animation-duration: $animate-css-animation-duration;
    animation-fill-mode: both;
    display: inline-block;
    cursor: pointer;
    color: white;

    &.checked {
      animation-name: pulse;
      color: darken($danger, 5%);
    }

    //@include hover-focus-active() {
    //  animation-name: pulse;
    //}
  }

  .product-already-ordered {
    color: $success;
    line-height: 1;
    font-size: .9rem;
  }

  .quantity-selector {
    &.multiple {
      background-color: transparent;
      cursor: pointer;

      .form-control-plaintext,
      input[readonly] {
        cursor: pointer;
        box-shadow: none;
      }

      .quantity-selector-quantity {
        background-color: transparent;
        .input-group {
          background-color: $white;
          border: 1px solid $primary;
          max-width: 110px;
          .app-icon-inner {
            display: none;
            flex: 0 1 44px;
            margin-left: 0;
            background-color: $primary;
            color: $white;
          }

          .form-control-plaintext,
          input[readonly] {
            text-align: center;
          }
        }
      }

      .btn-multiple {
        font-size: .99em;
        background-color: transparent;
        color: lighten($text-color, 20%);
        box-shadow: none;
        outline: none;
        border: 0 none;
        padding-left: 0;
        text-align: center;
        padding-right: 0;
        max-width: 110px;

        @include hover-focus-active() {
          background-color: transparent;
          color: lighten($text-color, 20%);
        }

        .app-icon {
          margin-right: $spacer / 2;
        }
      }

      &.has-errors {
        .input-group {
          //border-color: darken($warning, 20%);
        }
      }
    }
  }

  .product-badge {
    &:not(.product-badge-image) {
      padding: $badge-padding-y * 2 $badge-padding-x * 2;
      text-transform: uppercase;
      font-size: $font-size-base * 0.6;
      border-radius: 0;

      &.product-badge-new {
        background-color: $cyan;
        color: $white;
      }

      &.product-badge-best-seller {
        background-color: $warning;
        color: $text-color;
      }

      &.product-badge-best-seller {
        background-color: $gold;
        color: $text-color;
      }

      @include media-breakpoint-up(md) {
        font-size: $font-size-base * 0.8;
      }
    }

    &.product-badge-image {
      img {
        width: 30px;
        @include media-breakpoint-up(md) {
          width: 40px;
        }
      }
    }
  }

  .product-member-information {
    position: relative;
    margin-top: $spacer;

    &:before {
      content: " ";
      position: absolute;
      top: -8px;
      left: 15%;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 8px 7.5px;
      border-color: transparent transparent $gray-300 transparent;
    }

    &.has-callback {
      cursor: pointer;
    }

    .content {
      padding: $spacer / 4;
      background-color: $gray-300;
    }

    .orders-in-progress {
      tr {
        cursor: pointer;
      }
    }

    .product-weekly-stats-action {
      height: 100%;
      display: inline-flex
    ;
      align-items: center;
      justify-content: center;
    }

    .product-member-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      font-size: .8rem;

      &.has-subs {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .sub-product-field {
          & > .lbl,
          & > .value {
            display: inline-block;
          }
        }
      }

      .lbl {
        &:after {
          content: " : ";
          margin-right: .1rem;
        }
      }

      .value {
        font-weight: bold;
        line-height: 1.5;
      }

      .extra {
        font-style: italic;
      }

      &.has-callback {
        cursor: pointer;
        @include hover-focus-active() {
          text-decoration: underline;
        }
      }

      + .orders-in-progress {
        margin-top: $spacer / 2;
      }

      @include media-breakpoint-up(xxl) {
        flex-wrap: nowrap;

        .extra {
          margin-left: .2rem;
        }
      }

      &.quantity-exceeded {
        .remaining-stock {
          .value {
            color: darken($danger, 2%);
          }
        }
      }
    }
  }

  &-card {
    .product-head {
      position: relative;

      .product-badge {
        &-list {
          position: absolute;
          left: $spacer / 3;
          right: auto;
          top: $spacer / 3;
          z-index: 6;
          display: flex;
          flex-direction: column;

          & > span {
            margin-top: $spacer / 6;
            &:first-child {
              margin-top: 0;
            }
          }
        }

        @include media-breakpoint-up(md) {
          &-list {
            right: $spacer / 3;
            flex-direction: row;
            flex-wrap: wrap;
            & > span {
              margin-top: 0;
              margin-left: $spacer / 6;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .product-heading {
      margin: $spacer * 0.5 0;
      & > .product-name, & > .product-reference {
        margin-bottom: 0;
      }

      .product-already-ordered {
        display: none;
      }
    }

    .product-content {
      .row {
        & > .col + .col {
          margin-left: $spacer;
        }
      }
    }

    .product-name {
      @include truncate-text();
      @include font-size(1em);
      font-weight: bold;
      text-transform: uppercase;
    }

    .product-reference {
      @include font-size(.85em);
    }

    .product-packing,
    .product-price-list,
    .product-eco-tax {
      display: flex;
      flex-direction: column;
      padding-right: $spacer * 0.7;
    }

    .product-shipping-location {
      display: flex;
      flex-direction: row;
      align-items: center;
      .app-icon {
        @include font-size(1.2em);
        margin-right: .4em;
      }
    }

    .product-pick {
      display: none;
    }

    &.product-card-mode-picker {
      .product-image {

      }

      &.picked {
        .product-image {
          border: 2px solid $primary;
        }
      }

      .favorite,
      .product-overlay {
        display: none;
      }

      .product-pick {
        display: block;
        position: absolute;
        right: $spacer / 3;
        top: $spacer;
        z-index: 8;
      }
    }

    &.product-card-mode-light {
      .product-content {
        .product-name {
          display: none;
        }

        .product-extra .product-field {
          display: none;
        }

        .quantity-selector {
          margin-right: auto;
        }
      }

      .product-overlay {
        //.product-name {
        //  display: none;
        //}

        .product-heading .product-field {
          align-items: center;
          justify-content: center;

          .value {
            font-size: 1em;
          }
        }
      }
    }

    &.product-card-mode-expanded {
      background: white;
      font-size: 0.875rem;
      max-width: 98%;
      margin-left: auto;
      margin-right: auto;

      & > .product-content {
        padding: $spacer;

        .product-name {
          font-weight: 500;
          text-overflow: initial;
          overflow: auto;
          white-space: initial;
        }

        .not-orderable-message {
          margin-top: $spacer / 4;
          margin-bottom: 0;
        }
      }

      .product-brand {
        &-text {
          color: #9d9c9c;
          text-transform: uppercase;
          font-size: 0.875rem;
          margin-bottom: 0;

        }
      }

      .product-extra {
        text-transform: uppercase;
      }

      .quantity-selector {
        max-width: none;
        margin: $spacer * 0.5 0;
      }

      .product-detail-base-info {
        display: table;
        border-collapse: separate;
        vertical-align: middle;
        font-size: 0.813rem;
        margin: $spacer * 0.5 0;

        & > div {
          display: table-row;
          & > span {
            display: table-cell!important;
            vertical-align: middle;

            &.lbl {
              font-size: 0.96em;
              padding-left: 0;
              text-transform: uppercase;

              &:after {
                content: " : ";
                font-size: .85em;
                margin-right: .5rem;
              }
            }
          }

          &.product-shipping-location {

            @include media-breakpoint-down(sm) {
              .app-icon {
                display: none!important;
              }
            }

            &.with-label {
              &.with-icon {
                .app-icon {
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }

      .alert-info {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;

        min-width: 640px;

        padding: 10px 20px;

        & > .product-head {
          min-width: 192px;
          flex: 0 0 32%;
          max-width: 32%;
          padding: $spacer / 2 0;
        }

        & > .product-content {
          padding: 0;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          padding-left: 30px;
        }
      }
    }

    &-placeholder {
      .rect-shape,
      .round-shape,
      .text-row,
      .rect-shape,
      .round-shape,
      .text-row {
        background-color: gray;
        animation: react-placeholder-pulse 1.5s infinite;
      }

      .product-image {
        .rect-shape {
          width: 100%;
          height: 100%;
          margin: 0;
        }

        margin-bottom: $spacer / 2;
      }

      .text-row {
        margin-top: .2rem!important;
        max-height: .6rem!important;
        overflow: hidden!important;
      }

      .product-name-placeholder {
        margin-top: 0!important;
      }

      .product-reference-placeholder {
        max-width: 90px!important;
      }

      .product-quantity-placeholder {
        height: 35px!important;
      }
    }


    &.has-overlay {

      .product-overlay {
        transition: top linear $animate-css-animation-duration, bottom linear $animate-css-animation-duration;
        animation-duration: $animate-css-animation-duration;
        animation-fill-mode: both;
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        bottom: auto;
        padding: $spacer;
        z-index: 4;
        opacity: 0;

        &-inner {
          height: 100%;
          padding: $spacer / 2;
          background: $white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .product-heading {
          max-width: 100%;
        }

        .product-name {
          white-space: normal;
          text-align: center;
        }
      }

    }

    .product-layer {
      transition: top linear $animate-css-animation-duration, bottom linear $animate-css-animation-duration;
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $spacer;
      z-index: 4;

      &-inner {
        height: 100%;
        padding: $spacer / 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-out-of-stock {
        .product-layer-inner {
          .product-customer-arrival {
            margin-top: .4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .lbl {
              &:after {
                content: " : ";
              }
            }

            span {
              + span {
                margin-top: .1rem;
              }
            }
          }
        }

        p {
          border-radius: 5px;
          padding: $spacer / 2 $spacer;
          background-image: linear-gradient(120deg, #dfdfe0 16.67%, #f2f2f2 16.67%, #f2f2f2 50%, #dfdfe0 50%, #dfdfe0 66.67%, #f2f2f2 66.67%, #f2f2f2 100%);
          background-size: 17.32px 30.00px;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: $font-size-base;
        }
      }
    }

    @include hover-focus-active() {

      &:not(.products-list-item) {
        &.has-overlay {
          .favorite {
            color: $white;
            top: .5rem;
          }
        }
      }

      .product-overlay {
        animation-name: fadeIn;
        top: 0;
        bottom: 0;
        opacity: 1;
      }

      &:not(.product-card-mode-picker) {
        .product-layer {
          animation-name: fadeOut;
          top: 100%;
          bottom: auto;
          opacity: 0;
        }
        .product-layer {
          display: none;
        }
      }

      .product-image {
        &-multiple {
          img {
            top: -100%;

            + img {
              top: 0;
            }
          }
        }
      }
    }

    &.product-suggestion {
      .product-field {
        align-items: center;
        justify-content: flex-start;
      }
    } // .product-suggestion

    &.out-of-stock {
      .product-extra,
      .product-heading,
      .product-image-inner,
      .product-badge-list,
      .favorite,
      .product-member-information {
        transition: opacity linear $animate-css-animation-duration;
        opacity: 0.4;
      }

      &:active,
      &:hover,
      &:focus,
      &.picked {
        .product-extra,
        .product-heading,
        .product-image-inner,
        .product-badge-list,
        .favorite,
        .product-member-information {
          opacity: 0.9;
        }
      }
    }

  } // -.card

  .product-packing,
  .product-price {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .value {
      margin-left: auto;
      color: $primary;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: wrap;

      .strike-price {
        font-size: .7rem!important;
        margin-left: auto;
        text-decoration: line-through;
        //line-height: 1.6!important;
        color: $text-color!important;
        position: relative;
        top: -1px;
        line-height: 1.6;

        + .selling-price {
          margin-left: $spacer  / 3;
        }
      }
    }
  }

  .product-price {
    &-buying {
      .value {
        color: $primary;
      }
    }

    &-recommended-retail {
      @include font-size(0.85em);
      color: $gray-600;

      .value {
        color: inherit;
      }
    }
  }

  .quantity-selector {
    .form-group {
      margin-bottom: 0;
    }
  }

  .product-attributes-section {
    margin-bottom: .5rem;
    .product-attribute {
      margin-bottom: 0;
    }
  }

  .product-field,
  .product-attribute,
  .product-shipping-location {

    &:not(tr) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .lbl {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }

      &.with-label {
        .lbl {
          &:after {
            content: " : ";
            font-size: .85em;
            margin-right: .5rem;
            margin-left: .2rem;
          }
        }
      }

      .value {
        font-weight: 600;
        line-height: 1;
      }
    }
  }

  &-list-item {
    @include font-size(.9em);

    .favorite {
      display: block;
      position: absolute;
      right: 1rem;
      top: $spacer;
      z-index: 8;
      font-size: 1.3rem;
      color: $black;
    }

    .general-price {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1.1rem;
      z-index: 8;
      font-size: 1.2rem;
      color: $success;

      &.with-favorite {
        right: 2.85rem;
      }
    }

    .product-heading {
      .product-field {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .product-overlay {
      background-color: rgba(0, 0, 0, .5);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      .product-overlay-inner {
        max-width: 100%;
        width: 100%;
        height: auto;
        animation-duration: $animate-css-animation-duration * 2;
        animation-fill-mode: both;
      }

      .product-shipping-location {
        .value {
          position: relative;
          line-height: 1.5;
        }
      }

      .show-detail {
        color: $primary!important;
        background-color: transparent;
        border: 0 none;
        outline: none;
      }

      @include media-breakpoint-up(md) {
        .product-name {
          overflow: visible;
          text-overflow: initial;
          white-space: initial;
          text-align: center;
        }

        .product-overlay {
          .product-overlay-inner {
            animation-name: fadeInUp;
          }
        }
      }
    }
  }

  &-carousel-item {
    .product-overlay {
      animation-duration: $animate-css-animation-duration;
      animation-fill-mode: both;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $spacer;
      opacity: 0;

      &-inner {
        height: 100%;
        padding: $spacer / 2;
        background: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .product-heading {
        max-width: 100%;
      }
    }
  }  // -.carousel-item

  .product-overlay {
    .product-price-list {
      align-items: center;
    }

    .show-detail {
      margin-top: $spacer / 2;
      color: inherit;
      text-decoration: underline;
    }

    .product-packing,
    .product-price {
      font-size: 1em;

      .lbl {
        &:after {
          content: ': ';
          margin: 0 0.2em;
        }
      }
    }
  }
} // .product

.product-carousel {

  .show-detail {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 0 none;
  }

  .product-carousel-heading {
    text-align: center;
    margin-bottom: $spacer;
  }

  .product-carousel-item {
    .product-name {
      @include truncate-text();
      white-space: nowrap!important;
      width: 100%;
    }
  }

  .carousel-app-footer {
    text-align: center;

    .btn-detail {
      @include font-size(1em);
      padding: $btn-padding-y-lg * 1.5 $btn-padding-x-lg * 3;
    }
  }
}

.quantity-selector{
  max-width: 156px;
  margin-left: auto;

  .button-decrement {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button-increment {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn {
    padding: 0.375rem 0.55rem;

    &.inactive {
      opacity: $btn-disabled-opacity;

      &.btn-secondary {
        background-color: $gray-500;
        border-color: $gray-500;
      }

      @include box-shadow(none);
      cursor: default;

      @include hover-focus-active() {
        &.btn-secondary {
          background-color: $secondary;
          border-color: $secondary;
        }

        &.btn-primary {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    .app-icon {
      &.flaticon-plus,
      &.flaticon-minus {
        position: relative;
        left: 1px;
      }
    }

    &:not([disabled]) {
      @include hover-focus-active() {
        background-color: $gray-800;
        border-color: $gray-800;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
    margin-right: 0;
  }

  .input-group {
    flex-wrap: nowrap;

    .input-group-content {
      > .app-icon-inner {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: .42em;
        position: relative;

        & > .app-icon {
          width: 1.1rem;
          height: .9rem;
          text-align: center;

          &.svg-inline--fa {
            margin-top: 0;
          }

          &.app-icon-multiple {
            display: none;
          }
        }
      }
    }
  }

  input[type="number"],
  input[type="text"] {
    flex: 1;
    border: 0 none;
  }

  .input-group-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .quantity-selector-quantity {
    max-width: 156px;
    background-color: $gray-200;

    .form-control {
      background-color: transparent;
      flex: 1 1 auto;
      padding-left: .5rem;
      padding-right: .5rem;
      box-shadow: none!important;

      .is-ie & {
        max-width: 90px;
      }
      @include hover-focus-active() {
        outline: none;
        box-shadow: inset 0 -1px 0 #ddd;
      }
    }
  }

  &.has-errors {
    .app-icon-inner, .form-control {
      color: darken($warning, 20%);
    }
  }

  .product-list-member-salesman & {
    &.order-in-progress-quantity {
      .app-icon-inner, .form-control {
        color: $danger;
      }
    }
  }

  &.with-packing,
  &.with-price
  {
    & > form {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .form-control {
      max-width: 73%;
    }
  }

    &.with-packing {
    & > form {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.multiple {
      & > form {
        align-items: flex-start;
      }
    }

    .quantity-selector-packing {

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      .app-icon {
        font-size: 1.250rem;

        &.equal {
          margin: 0 .4rem;
        }
      }

      .value {
        margin-left: .4rem;
        font-weight: bold;
        background-color: white;
        flex: 1 1 auto;
        padding: 0 0.375rem;
      }

      .value {
        font-size: 1rem;
      }


      .input-group-prepend {
        .app-icon {
          font-size: 1.250rem;

          &.equal {
            margin: 0 .4rem;
          }
        }

        + input {
          margin-left: .4rem;
          font-weight: bold;
          background-color: white;
          flex: 1 1 auto;
          max-width: 38px;
          padding: 0.375rem;
        }
      }
    }
  }

  &.with-price {
    &.with-packing {
      .product-quantity-total-amount {
        padding-left: .9rem;
        border-left: 1px solid $gray-600;
      }
    }
  }

  &.with-price {
    .quantity-selector-price {
      .form-control {
        border: 0 none;
      }
      .product-quantity-total-amount {
        color: $primary;
        font-weight: bold;
      }
    }
  }

  &.restock-alert-only {
    background: $white;

    .btn {
      width: 100%;
      @include hover-focus-active() {
        background: transparent;

        &.btn-outline-primary {
          border-color: darken($primary, 10%);
          background-color: $primary;
          color: $white;
        }

        &.btn-outline-success {
          border-color: darken($success, 10%);
          background-color: $success;
          color: $white;
        }
      }
    }
  }

  &.out-of-stock {
    &.disabled {
      & > form {
        opacity: .4;
        //pointer-events: none;
      }
    }
  }

  .app-icon-multiple {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    &.with-packing {
      .quantity-selector-packing {
        .app-icon {
          &.equal {
            margin: 0 .8rem;
          }
        }
      }
    }
  }

  &.disabled {
    .input-group-content {
      background-color: $gray-200;
      opacity: .5;
    }
  }
}//.product-quantity-selector

.quantity-selector-alert {
  text-align: center;

  .title {
    font-weight: 600;
    font-size: $font-size-sm * 1.2;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  p {
    font-size: $font-size-sm;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .errors {
    position: relative;
    padding: $alert-padding-y / 2 $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    border: $alert-border-width solid transparent;
    @include border-radius($alert-border-radius);
    @include alert-variant(theme-color-level('warning', $alert-bg-level), theme-color-level('warning', $alert-border-level), theme-color-level('warning', $alert-color-level));

    .invalid-feedback {
      color: darken($warning, 20%)!important;
      display: block;
      margin-top: 0;
      text-align: center;
      font-weight: bold;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

}


.quantity-selector-alerts {
  margin-top: 1.5rem;
  .quantity-selector-alert {
    position: relative;
    box-shadow: $box-shadow-lg;
    margin-bottom: 1rem;
    padding: $spacer/2 $spacer;
    border-radius: 5px;
    background-color: $white;

    &.out-of-stock {
      border: 1px solid darken($warning, 10%);
    }

    &.max-reached,
    &.restock-alert {
      border: 1px solid darken($primary, 10%);
    }

    .arrow {
      display: none;
    }
  }

  & > div:first-child {
    .arrow {
      display: block;
      position:absolute;
      top:-11px;
      left:60px;
      content:"";
      background: $white;
      height:20px;
      width:20px;
      transform: rotate(224deg);
      border-bottom:1px solid $primary;
      border-right:1px solid $primary;

      &:after{

      }
    }
  }

  & > div:last-child {
    margin-bottom: 0;
  }
}

.quantity-selector-info {
  &.out-of-stock {
    border-color: darken($warning, 10%);
    .invalid-feedback {
      display: block;
      color: darken($warning, 20%);
    }
  }

  &.max-reached,
  &.restock-alert {
    border-color: darken($primary, 10%);
    .invalid-feedback {
      display: block;
      color: darken($primary, 20%);
    }
  }
} // .quantity-selector-info

.quantity-selector-popover {
  &.has-errors {
    &.restock-alert,
    &.out-of-stock {

    }
  }
} // .quantity-selector-popover

.restock-alert {
  button {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .app-icon {
      font-size: $font-size-lg;
      line-height: 1;
    }
  }

  .footer {
    text-align: center;
  }

  &.mode-button {
    .body {
      display: none;
    }

    button {
      .app-icon {
        font-size: $font-size-base;
        margin-right: .2rem;
      }

      .lbl {
        font-size: $font-size-base * 0.75;
      }

      @include media-breakpoint-up(md) {
        flex-direction: column;

        .app-icon {
          font-size: $font-size-lg;
          margin-right: .5rem;
        }

        .lbl {
          font-size: $font-size-base;
        }
      }
    }
  }

  &.mode-insert {
    button {
      flex-direction: row;
      border: 0 none;
      background: transparent;

      .app-icon {
        margin-right: .5rem;
      }

      .lbl {
        text-decoration: underline;
      }

      @include hover-focus-active() {
        border: 0 none;
        background-color: transparent!important;

        &.btn-outline-primary {
          color: darken($primary, 10%);
        }

        &.btn-outline-success {
          color: darken($success, 10%);
        }
      }
    }
  }

} // .restock-alert

.product-quantity-section {
  align-items: center;
  &.row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;

    [class*="col-"],
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .quantity-selector-quantity {
    &.multiple {
      .app-icon {
        &-shopping-cart {
          display: none;
        }

        &-multiple {
          display: block!important;
        }
      }
    }
  }
}

.product-attribute-selector {
  display: flex!important;
  flex-direction: row!important;
  justify-content: flex-start!important;
  align-items: center!important;

  margin-bottom: $spacer * 1.5;

  & > div {
    display: table-cell;
  }

  .lbl {
    text-transform: uppercase;

    &:after {
      content: ": ";
    }
    //margin-right: $spacer;
  }


  &.product-attribute-selector-mode-radio {
    .selectors {
      .form-check {
        label {
          cursor: pointer;
          padding: $btn-padding-y / 2 $btn-padding-x * 1.5;
          border: 1px solid $black;
          border-radius: $border-radius;
          color: $black;
        }
      }
    }
  }

  &.product-attribute-selector-mode-color {
    .selectors {
      .form-check {
        label {
          cursor: pointer;
          margin-bottom: 0;
          border: 1px solid $black;
          .product-selector-color {
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &.product-attribute-selector-mode-radio,
  &.product-attribute-selector-mode-color {
    .form-check {
      margin: .125rem .25rem .125rem 0;

      &.not-available {
        opacity: .4;

        @include hover-focus-active() {
          opacity: 1;
        }
      }
    }
  }

  &.product-attribute-selector-mode-radio,
  &.product-attribute-selector-mode-color {
    .form-check {
      position: relative;

      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;

        &:checked {
          //opacity: 1;
          //visibility: visible;
        }
      }

      input:checked + label {
        border-color: $primary;
        color: $primary;
      }

      @include hover-focus-active() {
        label {
          border-color: $primary;
          color: $primary;
        }
      }

      &.is-selected {
        label {
          border-color: $primary;
          color: $primary;
        }
      }
    }

    .product-selector-has-image {
      label {
        .product-selector-image {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.product-attribute-selectors {
  display: table;
  border-collapse: separate;
  vertical-align: middle;

  .product-attribute-selector {
    display: table-row;
    & > div {
      display: table-cell;
      vertical-align: middle;

      &.lbl {
        padding: .6rem;
        padding-left: 0;
      }
    }
  }
}

.product-description {
  text-align: justify;
  letter-spacing: .015em;
  line-height: 1.3em;
}

.product-detail {
  .product-detail-inner-section {
    margin-bottom: $spacer;
  }

  .product-image {
    .product-badge {
      &-list {
        position: absolute;
        left: $spacer / 6;
        top: $spacer / 6;
        display: flex;
        flex-direction: column;
        z-index: 6;

        & > span {
          margin-top: $spacer / 2;
          &:first-child {
            margin-top: 0;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &-list {
          right: $spacer / 3;
          flex-direction: row;
          flex-wrap: wrap;
          & > span {
            margin-top: 0;
            margin-left: $spacer / 6;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .product-image-list {
    .swiper {
      transition: background-color linear $animate-css-animation-duration / 2;
      background: transparent;
      &.swiper-initialized {
        background-color: $white;
      }
    }
  }

  .general-price {
    &.expanded {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .app-icon {
        margin-right: $spacer / 2;
      }
    }
  }

  .favorite {
    display: block;
    cursor: pointer;
    vertical-align: middle;
    .app-icon {
      margin-left: .3rem;
    }

    &.checked {
      .app-icon {
        color: $red;
      }
    }
  }

  .product-packing {
    font-weight: bold;
  }

  .product-price-list {
    display: table;
    & > span {
      display: table-row;

      .lbl,
      .price {
        font-size: .9rem;
      }

      .lbl {
        text-transform: uppercase;
      }

      & > span {
        display: table-cell;

        &.value {
          text-align: right;
          padding-left: $spacer * 1.5;
        }
      }

      &.product-price-buying {
        .lbl,
        .price {
          font-size: 1rem;
        }

        .lbl {
          font-weight: bold;
        }
        .price {
          font-weight: bold;
          color: $primary;


        }
      }

      &.product-eco-tax {
        .lbl {
          font-weight: bold;
        }
        .lbl,
        .price {
          font-style: italic;
          font-size: .85rem;
        }
      }
    }
  }

  .product-member-information {
    margin-top: $spacer / 2;

    &:before {
      content: "";
      display: none;
      border-bottom: 0 none;
    }

    .content {
      background: transparent;
    }

    &.has-callback {
      @include hover-focus-active() {
        text-decoration: underline;
      }
    }
  }
}

.product-attributes-section {
  .table {
    tbody {
      td {
        text-align: left;
      }
    }
  }

  .heading {
    margin-bottom: $spacer;
    & > h3 {
      text-align: center;
    }

    .app-icon {
      margin-right: .4rem;
    }
  }

  .packing {
    td {
      color: $primary;
    }
  }

  td {
    .product-shipping-location {
      justify-content: flex-start;
    }
  }
}

.product-detail-navigation {
  background: $gray-200;
  flex: 1;

  & > .row {
    & > div {
    }
  }

  .product-card {
    width: 50px;
  }

  button {
    padding: 0;
    background: transparent;
    border: 0 none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;

    &.btn-prev {
      margin-right: auto;

      .app-icon,
      .lbl {
        margin-left: .5rem;
      }
    }

    &.btn-next {
      margin-left: auto;
      .app-icon,
      .lbl {
        margin-right: .5rem;
      }
    }

    .lbl {
      font-size: 0.95em;

      &:not(.abbr) {
        display: none;
      }
    }
  }

  img {
    width: 50px;
    height: 50px;
  }

  @include media-breakpoint-up(md) {
    button {
      .lbl {
        display: inline-block!important;
        font-size: 0.95em;

        &.abbr {
          display: none!important;
        }
      }
    }
  }
}

.product-brand {
  &-text {
    font-size: $font-size-base * 1.2;
  }
}

.product-detail-navigation-popover {
  .product-suggestion {
    .product-name {
      text-overflow: initial;
      overflow: visible;
      height: auto;
      word-break: initial;
      word-wrap: initial;
      white-space: initial;
    }
  }

  @include media-breakpoint-up(md) {
    min-width: 350px;
  }
}

.product-image-lightbox {
  .copyright {
    text-align: center;

    p {
      font-style: italic;
      font-size: .9em;
      margin-bottom: 0;
    }
  }

  .ril__caption {
    justify-content: center;
  }
}


.product-filter-list {

  // DEBUG GLOBAL

  .heading {
    .badge {
      background-color: $gray-800;
      color: $white;
    }

    &:after {
      display: inline-block;
      font-family: $font-Flaticon-family;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }
  }

  .product-filter {
    .content {
      mark {
        padding: 0;
      }
    }

    .action {

      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: nowrap;

      .btn-save,
      .btn-cancel {
        border-top: 2px solid $gray-800;
        border-radius: 0;
        outline: none;
        box-shadow: none;
        text-decoration: none;
        padding: $spacer / 2 $spacer;
        font-size: 1.2em;
      }

      .btn-save {
        background-color: $gray-800;
        color: $white!important;

        &:not([disabled]) {
          @include hover-focus-active() {
            background-color: lighten($gray-800, 10%);
            border-top-color: lighten($gray-800, 10%);
          }
        }
      }

      .btn-cancel {
        border-right: 1px solid $gray-800;

        &:not([disabled]) {
          @include hover-focus-active() {
            background-color: $gray-100;
          }
        }
      }

      .btn-link {
        color: $text-color;
      }
    }

    &-type-range {
      .content {
        .filter-price-value {
          padding: 1rem;
        }

        .row {
          align-items: center;
        }
        .col-separator {
          padding: 0;
        }

        .separator {
          height: 2px;
          width: 40px;
          background-color: $gray-300;
          display: block;
        }

        .input-group {
          border: 1px solid $gray-300;

          input {
            font-size: 1.2em;
            margin-left: 0;
            outline: none;
            box-shadow: none;
          }

          .input-group-text {
            font-size: 1.4em;
          }

          input,
          .input-group-text,
          .input-group-append {
            background: transparent;
            border: 0 none;
            border-radius: 0;
          }
        }
      }
    }

    &-type-order,
    &-type-option {

      .product-filter-search {
        //margin: 0 2px 0 2px;
        box-shadow: inset 0 -1px 0 0 $gray-300;

        & > .form-group {
          padding: .4rem $spacer;
          margin-bottom: 0;

          .app-icon {
            font-size: 1.4em;
          }

          input {
            font-size: 1.2em;
            margin-left: 0;
            padding-left: 0;
            outline: none;
            box-shadow: none;
          }

          input,
          .input-group-text,
          .input-group-append {
            background: transparent;
            border: 0 none;
            border-radius: 0;
          }
        }
      }

      .values {
        overflow-y: scroll;
        //margin: 0 2px 2px;

        label {
          cursor: pointer;
        }

        & > .entry {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          position: relative;
          box-shadow: inset 0 -1px 0 0 $gray-300;
          margin-right: 0;
          cursor: pointer;
          padding: $spacer;

          @include hover-focus-active() {
            background-color: $gray-200;
          }

          .app-icon {
            display: none;
          }

          &.is-selected {
            background-color: $gray-100;
            .form-label-text {
              font-weight: bold;
            }

            .app-icon-selected {
              display: block;
              position: absolute;
              right: 10px;
              top: 50%;
              font-size: 1.3em;
              transform: translateY(-50%);
            }
          }
        }

        .form-label-image,
        .form-label-color {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          margin-right: $spacer / 2;
          border: 1px solid $gray-500;

          & > span {
            display: block;
            position: relative;
            width: 18px;
            height: 18px;

            & > img {
              max-width: 100%;
            }

            & > img,
            & > span {
              display: block;
              width: 100%;
              height: 100%;
            }

            & > span {
              &.no-color {
                display: flex;
                align-items: center;
                justify-content: center;
                .app-icon {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }


  }

  // FIN GLOBAL

  &.desktop {
    position: relative;
    &.disabled {
      z-index: 0;
      opacity: .7;
    }

    margin-bottom: $spacer;

    .btn-reset-all {
      padding: 0;
      color: #333;
      text-decoration: underline;
    }

    .product-filter-list-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & > div {
        margin-top: $spacer / 4;
        margin-bottom: $spacer / 4;
        margin-right: $spacer / 2;
        &:last-child {
          margin-right: 0;
        }

        &.title {
          display: none;
          //display: flex;
          align-items: center;
          justify-content: center;

          span {
            &:after {
              content: " :";
            }
          }
        }
      }
    }

    .product-filter {
      z-index: 1;
      max-height: 500px;
      padding-bottom: 0;

      .heading {
        cursor: pointer;
        position: relative;
        font-size: 1.1em;
        background: $white;
        padding: $spacer / 2 $spacer;
        box-shadow: inset 0 0 0 1px $gray-300;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .app-icon {
          font-size: .9em;
          margin-left: $spacer / 2;
        }

        .subtitle {
          display: none;
        }

        &:after {
          margin-left: $spacer / 2;
          content: map-get($flaticon-map, "chevron-down");
        }

        @include hover-focus-active() {
          background-color: $gray-200;
          box-shadow: inset 0 0 0 2px $gray-800;
        }
      }

      &:not(.empty) {
        .heading {
          box-shadow: inset 0 0 0 2px $gray-800;
          font-weight: bold;
        }
      }

      &.open {
        z-index: 20;
        position: relative;
        .heading {
          box-shadow: inset -2px 0 0 0 $gray-800, inset 2px 0 0 0 $gray-800, inset 0 2px 0 0 $gray-800, inset 0 -2px 0 0 $white;
          &:after {
            content: map-get($flaticon-map, "chevron-up");
          }
        }
      }

      .content {
        min-width: 288px;
        position: absolute;
        top: 95%;
        left: 100%;
        transform: translateX(-100%);
        border: 2px solid $gray-800;
        background: #fff;
        z-index: -1;

        mark {
          padding: 0;
        }
      }

      &-type-order,
      &-type-option {
        .values {
          max-height: 280px;
          overflow-y: scroll;
        }
      }

      &-classification {
        .heading {
          box-shadow: inset 0 0 0 2px $green-dark;
        }
      }
    }
  }
}

.general-price-alert {
  padding-left: 0;
  padding-right: 0;

  .app-icon {
    font-size: 1.1rem;
    color: $success;
  }
}

.product-detail-base-info {
  &.mode-table {

  }
  display: table;
  border-collapse: separate;
  vertical-align: middle;

  & > div {
    display: table-row;
    & > span {
      display: table-cell!important;
      vertical-align: middle;

      &.lbl {
        font-size: 0.96em;
        padding-left: 0;
        text-transform: uppercase;

        &:after {
          content: " : ";
          font-size: .85em;
          margin-right: .5rem;
        }
      }
    }

    &.product-shipping-location {

      @include media-breakpoint-down(sm) {
        .app-icon {
          display: none!important;
        }
      }

      //.lbl {
      //  text-transform: none;
      //}

      &.with-label {
        &.with-icon {
          .app-icon {
            margin-right: .5rem;
          }
        }
      }
    }
  }
}

.product-stats {
  &.product-stats-placeholder {
    .text-row {
      background-color: gray;
      animation: react-placeholder-pulse 1.5s infinite;
    }

    .text-row {
      min-width: 20px;
      margin-top: .2rem!important;
      max-height: .4rem!important;
      overflow: hidden!important;
    }
  }

  .table {
    text-align: center;

    tr {
      & > td:first-child,
      & > th:first-child {
        text-align: left;
      }
    }

    thead {
      tr {
        th:nth-child(even) {
          background: #e6e6e6;
        }
      }
    }
    tbody {
      tr {
        td:nth-child(even) {
          background: #f6f6f6;
        }
      }
    }

    thead,
    tbody {
      tr.current, tr th.current, tr td.current {
        background: lighten($primary, 45%);
        border-color: lighten($primary, 45%);
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  .product-stats-filters {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $spacer;
    padding-bottom: calc($spacer / 4);

    .btn {
      padding: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.product-stats-popover {
  .popover-body {
    padding: 5px;
  }

  .product-stats {
    .table {
      margin-bottom: 0;
    }
  }
}

.product-stats-action {
  .btn {
    color: $text-color;
  }

  .app-icon {
    font-size: 19px;
  }
}
